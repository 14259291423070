import { windowLoad, requestIdleCallback, appendLink } from "@/assets/js/utils";
import { silentReport } from "@/assets/js/report";
export default async function (ctx) {
    console.log("plugins/index");
    window.onunhandledrejection = (event) => {
        console.log("Logged in window.onunhandledrejection", event);
        silentReport(event);
    };
    (async function () {
        await windowLoad();
        await requestIdleCallback();
        import('@mdi/font/css/materialdesignicons.css');
        appendLink("https://unpkg.com/flag-icon-css@3.4.5/css/flag-icon.min.css");
        if (typeof document != 'undefined') {
            document.body.addEventListener('click', function (event) {
                const target = event.target;
                if (target && target.matches && target.matches('.v-overlay--active')) {
                    console.log('overlay click');
                    if (!document.querySelector('.v-dialog--active')) {
                        const overlayActive = document.querySelector('.v-overlay--active');
                        if (overlayActive) {
                            overlayActive.style.zIndex = '-99';
                        }
                    }
                }
            });
        }
    })();
}
const originalConsoleLog = console.log;
if (!process.client) {
    console.log = notObjectsLog;
    console.warn = notObjectsLog;
    console.error = notObjectsLog;
}
function notObjectsLog() {
    const args = [];
    for (const argument of arguments) {
        if ("string" != typeof argument && "number" != typeof argument && "boolean" != typeof argument) {
            continue;
        }
        args.push(argument);
    }
    originalConsoleLog(...args);
}
