import Vue from "@/libraries/vue";
import { languageFlags, getLocale } from '@/plugins/i18n';
import { routerReplace } from "@/plugins/router";
import { localeMatch } from "@/assets/js/utils";
import { moduleDebug } from "@/assets/js/debug";
import routesStore from "@/store/routes";
const localesStore = new Vue({
    name: "LocalesStore",
    data() {
        return {
            routeLocale: undefined,
            lang: "",
            languageDialog: {
                model: false,
                locale: ""
            }
        };
    },
    computed: {
        route: () => routesStore.route,
        locale() {
            const routeLocale = this.routeLocale;
            const defaultLocale = getLocale();
            console.log("locales locale", routeLocale, ":", defaultLocale);
            if ("undefined" == typeof routeLocale)
                return "";
            if (!localeMatch(routeLocale)) {
                return defaultLocale;
            }
            return routeLocale || "";
        },
    },
    watch: {
        route: {
            immediate: true,
            handler(route) {
                console.log("locales watch route", route);
                this.updateRoute(route);
            }
        },
        locale: {
            immediate: true,
            handler(locale) {
                console.log("locales watch locale", locale);
                if (!locale)
                    return;
                this.updateLocale(locale);
            }
        },
        routeLocale: {
            async handler(routeLocale) {
                const route = this.route;
                const defaultLocale = getLocale();
                if ("undefined" == typeof routeLocale)
                    return;
                if (!localeMatch(routeLocale)) {
                    const fullPath = route.fullPath;
                    const path = "/" + defaultLocale + fullPath;
                    routerReplace(path);
                }
            }
        }
    },
    methods: {
        updateRoute(route) {
            if (!route || !route.params)
                return;
            const routeLocale = route.params.locale;
            if (routeLocale === this.routeLocale)
                return;
            this.routeLocale = routeLocale;
        },
        updateLocale(locale) {
            console.log("locales updateLocale", locale);
            const localeArr = locale.split("-");
            const lang = localeArr[0];
            if (this.lang != lang) {
                this.lang = lang;
            }
        },
        localePath(path, locale) {
            locale = locale || this.locale || "es";
            const pathname = "/" == path[0] ? path : "/" + path;
            return "/" + locale + pathname;
        },
        localeFlag(locale) {
            locale = locale || localesStore.locale || "";
            if (languageFlags[locale]) {
                return languageFlags[locale].flag;
            }
            const lang = localesStore.lang;
            if (languageFlags[lang]) {
                return languageFlags[lang].flag;
            }
            return "";
        }
    }
});
export default localesStore;
moduleDebug(localesStore);
