import _window from "@/libraries/window";
import swal from "@/libraries/sweetalert";
import { i18n } from "@/plugins/i18n";
import report, { silentReport } from "@/assets/js/report";
import { stringify } from "@/assets/js/utils";
import { isCrawler } from "@/assets/js/device";
const addEventListener = _window.addEventListener;
addEventListener && addEventListener("unhandledrejection", function (promiseRejectionEvent) {
    const reason = promiseRejectionEvent.reason;
    if (401 == reason || 403 == reason) {
        console.log("ignored reason 401 || 403");
        return;
    }
    console.log("unhandledrejection", reason);
    console.error(reason, promiseRejectionEvent);
});
const consoleError = console.error;
console.error = async function (rawError, optionalParams) {
    const err = handleError(rawError, optionalParams);
    if (!err)
        return;
    const ignores = ["Navigation cancelled", "ChunkLoadError", "Loading chunk"];
    for (const ignore of ignores) {
        if ("string" == err.message && err.message.includes(ignore)) {
            return;
        }
    }
    if (err.error) {
        silentReport(err.error, optionalParams);
        return consoleError(err.error, optionalParams);
    }
};
export async function error(rawError, optionalParams) {
    console.warn(rawError, optionalParams);
    const err = handleError(rawError, optionalParams);
    if (!err)
        return;
    const msg = err.message;
    err.error && await report(err.error, optionalParams);
    if (!msg || "string" != typeof msg || isCrawler()) {
        return;
    }
    await swalError(msg);
}
export function throwSwal(error) {
    console.log("throwSwal");
    if (!error) {
        throw new Error();
    }
    const message = error && "string" != typeof error ? error.message : error;
    swalError(message);
    throw error instanceof Error ? error : new Error(message);
}
function handleError(error, optionalParams) {
    console.log("exceptions handleError", error, optionalParams);
    if (error && error.fileName) {
        console.log("exceptions error.fileName", error.fileName);
    }
    let message = error;
    message = message && message.data ? message.data : message;
    message = message && message.error ? message.error : message;
    message = message && message.message ? message.message : message;
    console.log("exceptions error message", message);
    const ignore = 401 == message || 403 == message;
    if (ignore) {
        console.log("ignored message 401 || 403");
        return false;
    }
    const chunkFailedError = swErrorCheck(message, optionalParams);
    if (chunkFailedError) {
        const silentMessage = "chunkFailedError error: " + message;
        "object" == typeof error ? (error.message = silentMessage) : (error = silentMessage);
        silentReport(error, optionalParams);
        return false;
    }
    if (vuetifyError(message)) {
        console.log("silent vuetifyError catch");
        return false;
    }
    if (asyncComputedError(message)) {
        if (optionalParams) {
            message = optionalParams;
        }
        else {
            const silentMessage = "asyncComputed silent error: " + message;
            setTimeout(function () {
                report(silentMessage, { error });
            }, 1);
            return false;
        }
    }
    const errorType = error instanceof Error;
    if (!errorType) {
        error = stringify(error);
    }
    return { error, message };
}
export function swErrorCheck(error, optionalParams) {
    const messageError = error && error.message ? error.message : error;
    let swErrorCheck = false;
    const errorString = "" + stringify(messageError);
    const paramsString = "" + stringify(optionalParams);
    if (errorString && errorString.match(/loading chunk .* failed/gi)) {
        swErrorCheck = true;
    }
    if (paramsString && paramsString.match(/loading chunk .* failed/gi)) {
        swErrorCheck = true;
    }
    if (errorString && errorString.match(/Cannot read property '\$scopedSlots' of undefined/gi)) {
        swErrorCheck = true;
    }
    if (errorString && errorString.match(/i\.setAttribute is not a function/gi)) {
        swErrorCheck = true;
    }
    if (!swErrorCheck) {
        console.log("exceptions false");
        return false;
    }
    return true;
}
export function swalNetworkError() {
    if (!i18n) {
        swal("No connection error");
        report("swalNetworkError !i18n");
        return;
    }
    swal(i18n.t("error_no_connection") || "No hay conexión", i18n.t("error_no_connection_msg") ||
        `No se ha podido conectar con nuestro servidor,
      porfavor revisa tu conexión o prueba nuevamente.`, "warning");
}
function swalError(msg) {
    if (!msg || "string" != typeof msg) {
        return;
    }
    const htmlError = msg.replace(/\s+/g, " ").match(/<body>(.*)<\/body>/);
    if (htmlError && htmlError.length >= 2) {
        const body = htmlError[1];
        const h1 = body.match(/<h1>(.*)<\/h1>/);
        if (h1 && h1.length >= 2) {
            msg = h1[1].trim();
        }
        else {
            msg = body.replace(/(<([^>]+)>)/gi, "").trim();
        }
    }
    console.log("swalError", msg);
    let title = "Error";
    const pointIndex = msg.indexOf(". ");
    const equalIndex = msg.indexOf(": ");
    if (pointIndex > 0) {
        title = msg.substring(0, pointIndex);
        msg = msg.substring(pointIndex + 1);
    }
    else if (equalIndex > 0) {
        title = msg.substring(0, equalIndex);
        msg = msg.substring(equalIndex + 1);
    }
    if (msg.length > 500) {
        msg = msg.substring(0, 500) + "...";
    }
    return swal("" + title, "" + msg, "error");
}
function vuetifyError(msg) {
    return msg && msg.indexOf && -1 != msg.indexOf("[Vuetify]");
}
function asyncComputedError(msg) {
    return msg && msg.indexOf && -1 != msg.indexOf("Error evaluating async computed property");
}
