import _window from "@/libraries/window";
import LocalStorage from "@/libraries/LocalStorage";
import Vue from "@/libraries/vue";
import axios from "@/libraries/axios";
import storage, { persistent } from "@/assets/js/storage";
import { timeout, stringify, API_URL } from "@/assets/js/utils";
import { error } from "@/assets/js/exceptions";
import { isCrawler } from '@/assets/js/device';
import { silentReport } from "@/assets/js/report";
import routesStore from "@/store/routes";
import swal from "sweetalert";
const usersStore = new Vue({
    name: "UsersStore",
    data() {
        const data = {
            loading: false,
            homePage: routesStore.homePage,
            loginDialog: {
                model: false,
                social: ""
            }
        };
        return data;
    },
    computed: {
        admin() {
            const me = this.me;
            return !!me && "112113313111247916905" == me.id;
        },
        email() {
            const me = this.me;
            if (!me)
                return "";
            return this.userEmail(me);
        },
        username() {
            const me = this.me;
            if (!me)
                return "";
            return me.name;
        },
    },
    asyncComputed: {
        async me() {
            if (!process.client)
                return;
            await timeout(1);
            let Authorization = await persistent.getItem("Authorization");
            if (!Authorization) {
                try {
                    Authorization = await getAuthorization();
                }
                catch (e) {
                    console.log("getAuthorization catch", e);
                }
            }
            if (Authorization) {
                try {
                    const response = await axios.get(API_URL + "/users/me", { headers: { Authorization } });
                    const me = response.data;
                    if (me)
                        return me;
                }
                catch (e) {
                    console.log("http.get catch", e);
                }
            }
            const storageMe = await storage.get(API_URL + "/users/me", "Object");
            if (storageMe)
                return storageMe;
            return false;
        },
        meId: {
            async get() {
                if (!process.client)
                    return;
                const me = this.me;
                if (me && me.id)
                    return me.id;
                const persistentID = await persistent.getItem("user_id");
                if (persistentID)
                    return Number(persistentID);
            },
            default: LocalStorage.getItem("me_id")
        }
    },
    watch: {
        me: {
            immediate: true,
            async handler(me, prevMe) {
                if (me && this.loginDialog.model) {
                    Vue.set(this.loginDialog, "model", false);
                }
                if (me && me.id) {
                    persistent.setItem("user_id", "" + me.id);
                }
            }
        }
    },
    methods: {
        async user(userId) {
            if (!userId)
                return;
            const response = await axios.get(API_URL + "/users/" + userId);
            return response.data;
        },
        userName(user) {
            if (!user)
                return "";
            if (user.name)
                return user.name;
            return "";
        },
        userIdName(userId) {
            const getUser = this.user;
            const userName = this.userName;
            return new Promise(function (resolve) {
                getUser(userId).then((user) => {
                    const name = user && userName(user);
                    resolve(name);
                });
            });
        },
        userEmail(user) {
            if (!user)
                return "";
            const userEmail = user.email;
            if (userEmail)
                return userEmail;
            return "";
        },
        async reset(Authorization) {
            console.log("reset", Authorization);
            let userResponse;
            const headers = { "cache-control": "max-age=0, no-cache, no-store, private" };
            if (Authorization)
                headers.Authorization = Authorization;
            try {
                userResponse = await axios.get(API_URL + "/users/me", { headers });
            }
            catch (e) {
                silentReport(e);
            }
            if (!userResponse) {
                console.log("!userResponse");
                return;
            }
            if (!userResponse.data) {
                console.log("!userResponse.data", userResponse);
                return;
            }
            const me = userResponse.data;
            if (!me.id) {
                silentReport("!me.id", { response: userResponse.data });
                console.log("!me.id", userResponse.data);
                return;
            }
            console.log("me", me);
            if (me && stringify(me) != stringify(this.me)) {
                this.me = me;
            }
            return me;
        },
        async checkLogin() {
            const me = this.me;
            const login = this.login;
            if (me)
                return;
            const logged = await login();
            if (logged)
                return;
            return new Promise(function () { });
        },
        async login() {
            console.warn("login");
            const reset = this.reset;
            if (usersStore.me) {
                return true;
            }
            if (!process.client || isCrawler()) {
                return false;
            }
            const Authorization = await getAuthorization();
            if (Authorization) {
                const me = await reset();
                if (me && me.id) {
                    return true;
                }
            }
            Vue.set(this.loginDialog, "model", true);
            return false;
        },
        loginWeb(driver) {
            const reset = this.reset;
            const location = _window.location;
            if (!location)
                return false;
            driver = driver || "google";
            const url = API_URL + "/auth/" + driver;
            window.open(url);
            window.addEventListener('message', function (e) {
                const data = e.data;
                if ("access_token" == data.action) {
                    const accessToken = data.token;
                    const Authorization = "Bearer " + accessToken;
                    axios.defaults.headers.Authorization = Authorization;
                    reset(Authorization);
                    persistent.setItem("Authorization", Authorization);
                }
            });
            return true;
        },
        async logout() {
            console.log("logout");
            delete axios.defaults.headers.Authorization;
            const promises = [];
            promises.push(storage.remove(API_URL + "/users/me"));
            promises.push(persistent.removeItem("login_driver"));
            promises.push(persistent.removeItem("Authorization"));
            promises.push(persistent.removeItem("access_token"));
            await Promise.all(promises);
            this.me = undefined;
            Vue.set(this.loginDialog, "model", true);
        }
    }
});
export default usersStore;
import("@/assets/js/debug").then(function (imported) {
    imported.moduleDebug(usersStore);
});
async function getAuthorization(driver, accessToken) {
    driver = driver || await persistent.getItem("login_driver");
    accessToken = accessToken || await persistent.getItem("access_token");
    if (!driver || !accessToken)
        return "";
    usersStore.loading = true;
    const authorized = await resolveAuthorization(driver, accessToken);
    usersStore.loading = false;
    return authorized;
}
async function resolveAuthorization(driver, accessToken) {
    const Authorization = "Bearer " + accessToken;
    await loginResponse(Authorization, driver);
    return Authorization;
}
async function loginResponse(Authorization, driver) {
    if (!Authorization) {
        await error("missing Authorization", { driver, Authorization });
        return;
    }
    axios.defaults.headers.Authorization = Authorization;
    axios.defaults.headers.common.Authorization = Authorization;
    await persistent.setItem("Authorization", Authorization);
    const logged = await usersStore.reset(Authorization);
    if (!logged) {
        await silentReport("not logged", { driver, Authorization });
        await usersStore.logout();
        swal("Error al hacer login, por favor intenta de nuevo");
        return;
    }
    Vue.set(usersStore.loginDialog, "model", false);
    Vue.set(usersStore.loginDialog, "social", driver);
}
