import Vue from "@/libraries/vue";
import { persistent } from "@/assets/js/storage";
import routesStore from "@/store/routes";
import localesStore from "@/store/locales";
import usersStore from "@/store/users";
export default Vue.extend({
    name: "Login",
    data() {
        const data = {
            loading: false,
        };
        return data;
    },
    computed: {
        routePage: () => routesStore.page,
        lang: () => localesStore.lang,
        loginDialog: () => usersStore.loginDialog,
        me: () => usersStore.me,
    },
    methods: {
        hideLoginDialog() {
            usersStore.loginDialog.model = false;
        },
        async login(driver) {
            this.loading = driver;
            console.log("login:", driver);
            persistent.setItem("login_driver", driver);
            const success = await usersStore.loginWeb(driver);
            if (!success)
                this.loading = false;
        },
    },
});
