import Vue from "@/libraries/vue";
export default async function swal(...args) {
    const swal = Vue.$swal;
    if (!swal) {
        console.log("!swal", Object.keys(Vue), Vue);
        return Promise.reject();
    }
    console.log("swal", Object.keys(Vue), Vue);
    return swal(...args);
}
