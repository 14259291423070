import Vue from "@/libraries/vue";
import axios from "@/libraries/axios";
import { API_URL } from "@/assets/js/utils";
import { moduleDebug } from "@/assets/js/debug";
const searchStore = new Vue({
    name: "SearchStore",
    data() {
        return {};
    },
    methods: {
        async search(str) {
            if (!str)
                return [];
            const name = encodeURI("%" + normalizeText(str) + "%");
            const response = await axios.get(API_URL + `/footprints?searchName=${name}`);
            return response.data;
        }
    }
});
export default searchStore;
moduleDebug(searchStore);
function normalizeText(txt) {
    return txt.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
