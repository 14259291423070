import _window from "@/libraries/window";
import Vue from "@/libraries/vue";
import loginDialog from "@/components/modals/login.vue";
import store from "@/store";
import routesStore from "@/store/routes";
import localesStore from "@/store/locales";
import usersStore from "@/store/users";
import searchStore from "@/store/search";
export default Vue.extend({
    name: "DefaultLayout",
    components: {
        loginDialog,
    },
    data() {
        return {
            searchFocus: false,
            searchModel: "",
            search: "",
            footprints: [],
            keepAliveProps: {
                include: ["Index", "Home", "New", "About"],
            },
        };
    },
    computed: {
        drawerDialog: () => store.drawerDialog,
        page: () => routesStore.page,
        locale: () => localesStore.locale,
        me: () => usersStore.me,
        searchActive() {
            const searchFocus = this.searchFocus;
            const searchModel = this.searchModel;
            return searchFocus || !!searchModel;
        },
        title() {
            const location = _window.location;
            return location ? location.host : "";
        },
        homeLink() {
            const locale = this.locale;
            return localesStore.localePath("/", locale);
        },
        newLink() {
            const locale = this.locale;
            return localesStore.localePath("/new", locale);
        },
        aboutLink() {
            const locale = this.locale;
            return localesStore.localePath("/about", locale);
        },
    },
    watch: {
        async search(search) {
            this.footprints = await searchStore.search(search);
        },
        searchModel(searchModel) {
            if (searchModel) {
                this.$nextTick(() => {
                    this.search = "";
                    this.searchModel = "";
                    this.$refs.autocomplete.blur();
                });
                this.$router.push(localesStore.localePath(`/${searchModel}`));
            }
        },
    },
    methods: {
        login: usersStore.login,
        logout: usersStore.logout,
    },
});
