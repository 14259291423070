import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3553a3f1 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _738fd5ef = () => interopDefault(import('../src/pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _53d7fb2a = () => interopDefault(import('../src/pages/_lang/about.vue' /* webpackChunkName: "pages/_lang/about" */))
const _09f77704 = () => interopDefault(import('../src/pages/_lang/all.vue' /* webpackChunkName: "pages/_lang/all" */))
const _19305796 = () => interopDefault(import('../src/pages/_lang/logged.vue' /* webpackChunkName: "pages/_lang/logged" */))
const _c1434606 = () => interopDefault(import('../src/pages/_lang/new.vue' /* webpackChunkName: "pages/_lang/new" */))
const _164b401c = () => interopDefault(import('../src/pages/_lang/_id/_name.vue' /* webpackChunkName: "pages/_lang/_id/_name" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _3553a3f1,
    name: "index"
  }, {
    path: "/:lang",
    component: _738fd5ef,
    name: "lang"
  }, {
    path: "/:lang/about",
    component: _53d7fb2a,
    name: "lang-about"
  }, {
    path: "/:lang/all",
    component: _09f77704,
    name: "lang-all"
  }, {
    path: "/:lang/logged",
    component: _19305796,
    name: "lang-logged"
  }, {
    path: "/:lang/new",
    component: _c1434606,
    name: "lang-new"
  }, {
    path: "/:lang/:id/:name?",
    component: _164b401c,
    name: "lang-id-name"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
