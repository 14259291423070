import { request as requestIdleCallbackShim } from "requestidlecallback";
import _window from "@/libraries/window";
export function formatNumber(value, maxDecimals) {
    if (!value) {
        return "" + value;
    }
    const parts = value.toString().split(/\.|,/);
    if (parts.length > 2) {
        return "" + value;
    }
    const integer = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let decimal = parts[1] || "";
    if (decimal && "number" === typeof maxDecimals) {
        decimal = (parts[1] || "").substring(0, maxDecimals);
    }
    decimal = decimal ? "," + decimal : "";
    return integer + decimal;
}
export function formatPrice(value, maxDecimals) {
    maxDecimals = 2;
    return formatNumber(value, maxDecimals);
}
export function isType(value, type) {
    if (!value) {
        return true;
    }
    const isType = "[object " + type + "]" === Object.prototype.toString.call(value);
    if (!isType) {
        console.warn("!isType", type, value);
    }
    return isType;
}
export async function windowLoad() {
    const document = _window.document;
    const addEventListener = _window.addEventListener;
    if (document && document.readyState !== "complete") {
        return new Promise(function (resolve) {
            addEventListener && addEventListener("load", function (event) {
                console.log("onload");
                resolve();
            });
        });
    }
    await timeout(1);
}
export function timeout(ms = 0) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export async function requestIdleCallback(ms) {
    await windowLoad();
    return new Promise(resolve => requestIdleCallbackShim(resolve, ms));
}
export function eventCallback(event) {
    const addEventListener = _window.addEventListener;
    if (!addEventListener)
        return;
    return new Promise(resolve => addEventListener(event, resolve, false));
}
export function timestampDate(date) {
    if (!date)
        return;
    const arr = date.split(/[- :]/);
    return new Date(Number(arr[0]), Number(arr[1]) - 1, Number(arr[2]), Number(arr[3]), Number(arr[4]), Number(arr[5]));
}
export function dateTimestamp(date) {
    const year = date.getUTCFullYear();
    let yearString = year + "-";
    if (new Date().getFullYear() == year) {
        yearString = "";
    }
    return yearString +
        ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
        ('00' + date.getUTCDate()).slice(-2) + ' ' +
        ('00' + date.getUTCHours()).slice(-2) + ':' +
        ('00' + date.getUTCMinutes()).slice(-2);
}
export function DOMContentLoaded() {
    return new Promise(function (resolve) {
        const document = _window.document;
        if (!document) {
            return;
        }
        document.addEventListener("DOMContentLoaded", () => {
            resolve();
        });
        if (document.readyState === "complete" || document.readyState === "interactive") {
            resolve();
        }
    });
}
export function openSystem(event) {
    const open = _window.open;
    if (!open) {
        return;
    }
    let href = event.target.getAttribute("href");
    if (href) {
        open(href, "_system");
        return;
    }
    if (event.target.closest) {
        href = event.target.closest("a").getAttribute("href");
        open(href, "_system");
    }
}
export function push(arr, push) {
    if (!Array.isArray(push)) {
        push = [push];
    }
    const jsonArr = arr.map(function (el) {
        return stringify(el);
    });
    for (const el of push) {
        if (!jsonArr.includes(stringify(el))) {
            arr.push(el);
        }
    }
    return arr;
}
export function markdown(txt) {
    if (!txt)
        return "";
    return txt.replace(/\*\*([^*]*)\*\*/g, "<b>$1</b>");
}
;
if (process.client) {
    const w = window;
    w.linkifyOpen = async function (url) {
        w.analytics.track('outbound', { url }, null, function () {
            w.open(url, '_system');
        });
    };
}
export function linkify(inputText) {
    const replacePattern = /((^| )(https?:\/\/)?(www\.)?([a-z\.]*\.[a-z]{2,3}[a-z\/-]*)( |$))/gim;
    inputText = inputText.replace(replacePattern, `$2<a
      href="http://$4$5" target="_blank"
      onclick="linkifyOpen('http://$4$5'); return false;"
    >$5</a>`);
    return inputText;
}
export function imagefy(inputText) {
    let replacedText;
    let replacePattern;
    replacePattern = /(^| )([a-z0-9\:\/\.\-]+(\.jpg|\.jpeg|\.png|\.svg))( |$)/gim;
    replacedText = inputText.replace(replacePattern, '$1<img src="$2"/>$4');
    console.log("imagefy", inputText, replacedText);
    return replacedText;
}
export function scalefy(str, scale) {
    const regex = /(^| )~([0-9]+(\.[0-9]+)?)/gim;
    return str.replace(regex, function (match, $1, $2, $3) {
        return $1 + ($2 * scale) + $3;
    });
}
export function headerAttr(headers, headerName, attr) {
    if (!headers || !headerName || !attr) {
        return;
    }
    const header = headers[headerName];
    if (!header) {
        return;
    }
    const regeEx = new RegExp(".*max-age=([0-9]+).*");
    const match = header.match(regeEx);
    if (match) {
        return match[1];
    }
}
export function deepCopy(data) {
    return JSON.parse(stringify(data));
}
export function objectFilter(obj, predicate) {
    return Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key], res), {});
}
export function capitalize(str) {
    if (!str) {
        return "";
    }
    str = str.toString().toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function stringify(data, ...args) {
    if ("undefined" === typeof data) {
        return "";
    }
    if ("string" == typeof data) {
        return "" + data;
    }
    try {
        return JSON.stringify(data, ...args) || "";
    }
    catch (e) {
        console.warn("!stringify", data);
    }
    return "";
}
export function parse(str) {
    if (!str) {
        return;
    }
    try {
        return JSON.parse(str);
    }
    catch (e) {
    }
}
export function isInViewport(query) {
    const document = _window.document;
    if (!document) {
        return false;
    }
    const dom = document.querySelector(query);
    if (!dom) {
        return false;
    }
    const offset = dom.getBoundingClientRect();
    if (!offset) {
        return false;
    }
    const elementTop = offset.top;
    const elementBottom = elementTop + (dom.offsetHeight || 0);
    const viewportTop = document.documentElement.scrollTop;
    let windowHeight;
    const height = getComputedStyle(document.body, null).height;
    if (height) {
        windowHeight = parseFloat(height.replace("px", ""));
    }
    if (!viewportTop || !windowHeight) {
        return false;
    }
    const viewportBottom = viewportTop + windowHeight;
    console.log("isInViewport", elementBottom, viewportTop, elementTop, viewportBottom);
    return elementBottom > viewportTop && elementTop < viewportBottom;
}
export function fakePromise(promise) {
    return {
        then(thenCallback) {
            let error;
            try {
                promise(thenCallback);
            }
            catch (e) {
                error = e;
            }
            return {
                catch(callback) {
                    if (callback && error) {
                        callback(error);
                    }
                }
            };
        }
    };
}
export function appendLink(href, callback) {
    const document = _window.document;
    if (!document) {
        return;
    }
    const link = document.createElement("link");
    const head = document.getElementsByTagName("head")[0];
    link.addEventListener("load", function () {
        if (callback) {
            callback();
        }
    });
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = href;
    head.appendChild(link);
    return link;
}
export function appendLinkContent(content) {
    const document = _window.document;
    if (!document) {
        return;
    }
    const link = document.createElement("style");
    const head = document.getElementsByTagName("head")[0];
    link.type = "text/css";
    link.innerHTML = content;
    head.appendChild(link);
    return link;
}
export function visibilityUpdate(callback) {
    const doc = _window.document;
    if (!doc) {
        return;
    }
    let hidden = "hidden";
    if ("hidden" in doc) {
        doc.addEventListener("visibilitychange", onchange);
    }
    else if ("mozHidden" in doc) {
        hidden = "mozHidden";
        doc.addEventListener("mozvisibilitychange", onchange);
    }
    else if ("webkitHidden" in doc) {
        hidden = "webkitHidden";
        doc.addEventListener("webkitvisibilitychange", onchange);
    }
    else if ("msHidden" in doc) {
        hidden = "msHidden";
        doc.addEventListener("msvisibilitychange", onchange);
    }
    else if ("onfocusin" in doc) {
        doc.onfocusin = doc.onfocusout = onchange;
    }
    else {
        _window.onpageshow = _window.onpagehide = _window.onfocus = _window.onblur = onchange;
    }
    function onchange(evt) {
        const evtMap = {
            focus: false, focusin: false, pageshow: false, blur: true, focusout: true, pagehide: true
        };
        evt = evt || _window.event;
        if (evt.type in evtMap) {
            const isHidden = evtMap[evt.type];
            callback(!isHidden);
        }
        else {
            const isHidden = doc[hidden];
            callback(!isHidden);
        }
    }
}
;
export async function pageShow() {
    return new Promise(function (resolve) {
        visibilityUpdate(function (visible) {
            if (visible) {
                resolve();
            }
        });
    });
}
const protocol = ((_window.location && _window.location.protocol) || "http:") + "//";
export let APP_URL = process.env.APP_URL;
if (APP_URL && !APP_URL.includes("//"))
    APP_URL = protocol + APP_URL;
console.log("APP_URL", APP_URL);
export let API_URL = process.env.API_URL;
if (API_URL && !API_URL.includes("//"))
    API_URL = protocol + API_URL;
console.log("API_URL", API_URL);
export function apiUrl() {
    let apiUrl = API_URL;
    const location = _window.location;
    if (!API_URL && location && location.host.includes("click-to-vote\.")) {
        const protocol = location.host.includes("click-to-vote\.net") ? "https:" : location.protocol;
        apiUrl = protocol + "//api.click-to-vote" + location.host.split("click-to-vote")[1];
    }
    return apiUrl || "https://api.icnitas.com";
}
export function api2Url() {
    return API_URL || "https://api.icnitas.com";
}
export function apiUploads() {
    const location = _window.location;
    if (!location) {
        return "";
    }
    const hostArr = location.host.split(".");
    return location.protocol + "//uploads." + hostArr.slice(hostArr.length - 2).join(".");
}
export function normalize(txt) {
    let normalized = txt;
    try {
        normalized = txt.normalize("NFD");
    }
    catch (e) {
    }
    return normalized.replace(/[\u0300-\u036f]/g, "")
        .trim()
        .toLowerCase()
        .replace(/[ |-|(|)|'|"|,]+/gi, "_");
}
export function localeMatch(locale) {
    if (!locale)
        return;
    const match = locale.match(/^([a-zA-Z]{2,3})(?:-([a-zA-Z]{2}))?$/);
    if (!match)
        return;
    const localeArr = locale.split("-");
    const lang = localeArr[0];
    if ("adm" == lang)
        return;
    return match;
}
export function isProduction() {
    const location = _window.location;
    const host = location && location.host;
    if (host) {
        if ((!host.includes("icnitas.com"))
            || 0 === host.indexOf("dev.")
            || 0 === host.indexOf("v2.")
            || 0 === host.indexOf("beta.")) {
            return false;
        }
    }
    return true;
}
