import localForage from 'localforage';
import _window from '@/libraries/window';
import LocalStorage from "@/libraries/LocalStorage";
import { headerAttr, isType, windowLoad, stringify } from "@/assets/js/utils";
export let BROKEN_STORAGE = false;
export const ONLINE_CACHE_TIME = 120;
export const EDITED_CACHE_TIME = 1440;
export const OFFLINE_CACHE_TIME = 40000;
localForage.defineDriver({
    _driver: 'none',
    _initStorage: async () => { },
    iterate: async () => { return {}[0]; },
    getItem: async () => { return {}[0]; },
    setItem: async () => { return {}[0]; },
    removeItem: async () => { },
    clear: async () => undefined,
    length: async () => 0,
    key: async () => "",
    keys: async () => [],
});
localForage.setDriver([localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE, "none"]);
async function getLocalForage() {
    if (!process.client)
        return;
    return localForage;
}
getLocalForage().then(async function (localForage) {
    if (!localForage)
        return;
    let keys;
    try {
        keys = await localForage.keys();
    }
    catch (e) {
        return;
    }
    for (const key of keys) {
        if (key.startsWith("http"))
            removeItem(key);
    }
    await windowLoad();
    const timeNow = now();
    console.log("storage keys", keys.length);
    for (const key of keys) {
        if (/-time$/.test(key)) {
            const plainKey = key.replace(/-time$/, "");
            if (!keys.includes(plainKey)) {
                await removeItem(key);
                continue;
            }
            let time;
            try {
                time = await localForage.getItem(key);
            }
            catch (e) {
                continue;
            }
            if (timeNow - Number(time) > OFFLINE_CACHE_TIME) {
                console.log("remove cache", plainKey);
                await removeItem(plainKey);
            }
        }
        if (!/-time$/.test(key)) {
            const expiration = key + "-time";
            if (!keys.indexOf(expiration)) {
                await removeItem(key);
                continue;
            }
        }
    }
});
export default {
    async set(key, value, options) {
        const localForage = await getLocalForage();
        if (!localForage) {
            console.log("!localForage", key);
            return;
        }
        const edited = !options || !options.original;
        if (edited) {
            const current = await localForage.getItem(key);
            if (stringify(current) == stringify(value)) {
                return;
            }
        }
        try {
            if (edited) {
                localForage.setItem(key + "-edited", 1);
            }
            localForage.setItem(key + "-time", now());
            localForage.setItem(key, value);
        }
        catch (e) {
            console.log("storage set error", e);
        }
    },
    async get(key, type, headers, httpFallback) {
        const localForage = await getLocalForage();
        if (!localForage) {
            console.log("!localForage", key);
            return;
        }
        if (LocalStorage.getItem("admin")) {
            console.log("NO CACHE ADMIN");
            return;
        }
        let data;
        try {
            data = await localForage.getItem(key);
        }
        catch (e) {
        }
        if ("undefined" === typeof data || null === data)
            return;
        const wrongType = !isType(data, type);
        if (wrongType) {
            removeItem(key);
            return;
        }
        const cacheTimeOver = await this.cacheTimeOver(key, headers);
        if (cacheTimeOver)
            return;
        return data;
    },
    async cacheTimeOver(key, headers) {
        const localForage = await getLocalForage();
        if (!localForage)
            return false;
        let keyTime;
        let keyEdited;
        try {
            keyTime = await localForage.getItem(key + "-time");
            keyEdited = await localForage.getItem(key + "-edited");
        }
        catch (e) {
            return false;
        }
        const CACHE_TIME = (keyEdited && namedId(key)) ? EDITED_CACHE_TIME : ONLINE_CACHE_TIME;
        const creationTime = Number(keyTime);
        const timeNow = now();
        const overrideTime = Number(headerAttr(headers, "cache-control", "max-age"));
        const cacheTime = (overrideTime / 60) || CACHE_TIME;
        return !creationTime || (timeNow - creationTime) > cacheTime;
    },
    async stale(key, type) {
        const localForage = await getLocalForage();
        if (!localForage)
            return;
        let data;
        try {
            data = await localForage.getItem(key);
        }
        catch (e) {
            return;
        }
        if ("undefined" === typeof data || null === data) {
            return;
        }
        const wrongType = !isType(data, type);
        if (wrongType) {
            removeItem(key);
            return;
        }
        return data;
    },
    async remove(key) {
        return removeItem(key);
    },
    async edited(key) {
        const localForage = await getLocalForage();
        if (!localForage) {
            return null;
        }
        return await localForage.getItem(key + "-edited");
    }
};
export const persistent = {
    async getItem(key, safe = true) {
        const localStorageValue = LocalStorage.getItem(key);
        if (localStorageValue) {
            return localStorageValue;
        }
        const storage = await persistentLocalForage();
        const localForageValue = storage && await storage.getItem(key);
        if (localForageValue) {
            return "" + localForageValue;
        }
        if (false !== safe) {
            await windowLoad();
        }
        if (_window.NativeStorage) {
            const nativeStorageValue = await new Promise((resolve, reject) => {
                _window.NativeStorage.getItem(key, resolve, reject);
            }).catch((e) => {
                console.log(e);
            });
            if (nativeStorageValue) {
                return "" + nativeStorageValue;
            }
        }
        return "";
    },
    async setItem(key, value) {
        LocalStorage.setItem(key, value);
        const storage = await persistentLocalForage();
        storage && await storage.setItem(key, value);
        await windowLoad();
        _window.NativeStorage && await _window.NativeStorage.setItem(key, value);
    },
    async removeItem(key) {
        LocalStorage.removeItem(key);
        const storage = await persistentLocalForage();
        storage && await storage.removeItem(key);
        _window.NativeStorage && await _window.NativeStorage.remove(key);
    }
};
let PERSISTENT_LOCAL_FORAGE;
async function persistentLocalForage() {
    if (!process.client)
        return;
    if (PERSISTENT_LOCAL_FORAGE)
        return PERSISTENT_LOCAL_FORAGE;
    const localForage = await getLocalForage();
    if (!localForage)
        return;
    try {
        PERSISTENT_LOCAL_FORAGE = await localForage.createInstance({ name: "persistent" });
    }
    catch (e) {
    }
    return PERSISTENT_LOCAL_FORAGE;
}
async function removeItem(key) {
    const localForage = await getLocalForage();
    if (!localForage) {
        return;
    }
    try {
        const removes = [localForage.removeItem(key)];
        if (!/-time$/.test(key)) {
            removes.push(localForage.removeItem(key + "-time"));
            removes.push(localForage.removeItem(key + "-edited"));
        }
        await Promise.all(removes);
    }
    catch (e) {
    }
}
function now() {
    return Math.round(+new Date() / 60000);
}
function namedId(key) {
    const keyArr = key.split("/");
    const keyId = 2 == keyArr.length ? keyArr[1].split("?")[0] : null;
    return !!keyId && !Number(keyId);
}
