import _window from "@/libraries/window";
import { localeMatch, API_URL } from "@/assets/js/utils";
import { persistent } from "@/assets/js/storage";
export let i18n;
export default function ({ app, params }) {
    i18n = app.i18n;
    i18n.setLocale(params.locale);
    app.router.afterEach((to) => {
        i18n.setLocale(to.params.locale);
    });
    i18n.missing = () => {
        return "";
    };
}
const location = _window.location;
const pathLevel1 = location && location.pathname.split("/")[1] || "";
const pathLocaleData = getLocaleData(pathLevel1);
export let LANGUAGE = pathLocaleData.language;
if (process.client) {
    (async function () {
        const persistentLanguage = await persistent.getItem("language", false);
        if (persistentLanguage && localeMatch(persistentLanguage)) {
            if (LANGUAGE && 0 != persistentLanguage.indexOf(LANGUAGE)) {
                import("@/store/locales").then((localesStore) => {
                    localesStore.default.languageDialog.locale = LANGUAGE;
                    localesStore.default.languageDialog.model = true;
                });
                return;
            }
            LANGUAGE = persistentLanguage;
            updateRouteLocale();
            return;
        }
        if (!LANGUAGE) {
            const LOCALE = getNavigatorLanguage();
            console.log("LOCALE", LOCALE);
            if (LOCALE) {
                const localeData = getLocaleData(LOCALE);
                console.log("localeData", localeData);
                LANGUAGE = localeData.language;
            }
            if (!LANGUAGE) {
                LANGUAGE = "es";
            }
        }
        console.log("LANGUAGE", LANGUAGE);
        updateRouteLocale();
    })();
}
export async function updateRouteLocale(locale) {
    if (!process.client || !location)
        return;
    console.log("updateRouteLocale()", locale);
    if (locale) {
        LANGUAGE = locale;
        persistent.setItem("language", locale);
        import("@/store/users").then((usersStore) => {
            const me = usersStore.default.me;
            if (me && me.locale != locale) {
                import("@/libraries/axios").then(axiosImport => {
                    const axios = axiosImport && axiosImport.default;
                    axios && axios.patch(API_URL + "/users/" + me.id, { locale });
                });
            }
        });
    }
    locale = locale || getLocale();
    let fullPath = location.pathname + location.search + location.hash;
    const pathArr = fullPath.replace(/^\/|\/$/g, '').split("/");
    const currLocale = pathArr[0];
    if (currLocale && currLocale == locale)
        return;
    if (localeMatch(currLocale)) {
        fullPath = "/" + pathArr.slice(1).join("/");
    }
    const routerImport = await import("@/plugins/router");
    const router = routerImport.router;
    const redirect = "/" + locale + fullPath;
    if (router) {
        if (router.currentRoute.path != redirect)
            router.push(redirect);
    }
    else {
        if (location.pathname != redirect)
            location.href = redirect;
    }
}
export function getLocale() {
    const languageArr = LANGUAGE.split("-");
    const lang = languageArr[0];
    if ("es" == lang) {
        const locale = lang.toLowerCase();
        return locale;
    }
    return lang;
}
;
function getNavigatorLanguage() {
    const _navigator = "undefined" != typeof navigator ? navigator : null;
    if (!_navigator)
        return;
    const languages = _navigator.languages;
    if (languages
        && Object.prototype.toString.call(languages) === '[object Array]'
        && languages.length) {
        const languagesArr = languages.slice();
        const esLocales = languagesArr
            .find(language => 0 == language.indexOf("es-") && "es-es" != language.toLowerCase());
        if (esLocales)
            return esLocales;
        if (languagesArr.includes("es") || languagesArr.includes("es-ES")) {
            return "es";
        }
    }
    return _navigator.language || _navigator.browserLanguage || _navigator.userLanguage;
}
function getLocaleData(locale) {
    let language = "";
    if (locale) {
        const match = localeMatch(locale);
        if (match) {
            language = match[1] ? match[1].toLowerCase() : language;
        }
    }
    if ("fil" == language) {
        language = "tl";
    }
    if ("rm" == language) {
        language = "it";
    }
    locale = language;
    return {
        locale,
        language
    };
}
export const languageFlags = {
    af: { flag: "za", name: "afrikaans" },
    sq: { flag: "al", name: "shqiptar" },
    am: { flag: "et", name: "አማርኛ" },
    ar: { flag: "sa", name: "عربى" },
    hy: { flag: "am", name: "հայերեն" },
    az: { flag: "az", name: "azərbaycan" },
    eu: { flag: "es", name: "euskal" },
    be: { flag: "by", name: "Беларуская" },
    bn: { flag: "in", name: "বাঙালি" },
    bs: { flag: "ba", name: "Bosanski" },
    bg: { flag: "bg", name: "български" },
    ca: { flag: "es", name: "català" },
    ceb: { flag: "ph", name: "cebuano" },
    ny: { flag: "mw", name: "chichewa" },
    zh: { flag: "cn", name: "简体中文" },
    "zh-TW": { flag: "tw", name: "中國傳統的" },
    co: { flag: "fr", name: "corsu" },
    hr: { flag: "hr", name: "hrvatski" },
    cs: { flag: "cz", name: "čeština" },
    da: { flag: "dk", name: "dansk" },
    nl: { flag: "nl", name: "nederlands" },
    en: { flag: "us", name: "english" },
    et: { flag: "ee", name: "Eesti keel" },
    tl: { flag: "ph", name: "Filipino" },
    fi: { flag: "fi", name: "Suomalainen" },
    fr: { flag: "fr", name: "français" },
    fy: { flag: "nl", name: "Frysk" },
    gl: { flag: "es", name: "Galego" },
    ka: { flag: "ge", name: "ქართული" },
    de: { flag: "de", name: "Deutsche" },
    el: { flag: "gr", name: "Ελληνικά" },
    gu: { flag: "in", name: "ગુજરાતી" },
    ht: { flag: "ht", name: "Kreyòl Ayisyen" },
    ha: { flag: "ne", name: "Hausa" },
    haw: { flag: "us", name: "Hawaiian" },
    he: { flag: "il", name: "עברית" },
    hi: { flag: "in", name: "हिंदी" },
    hmn: { flag: "cn", name: "Hmoob" },
    hu: { flag: "hu", name: "Magyar" },
    is: { flag: "is", name: "Íslensku" },
    ig: { flag: "ng", name: "Igbo" },
    id: { flag: "id", name: "bahasa Indonesia" },
    ga: { flag: "ie", name: "Gaeilge" },
    it: { flag: "it", name: "italiano" },
    ja: { flag: "jp", name: "日本人" },
    jw: { flag: "id", name: "Jawa" },
    kk: { flag: "kz", name: "Қазақша" },
    km: { flag: "kh", name: "ភាសាខ្មែរ" },
    ko: { flag: "kr", name: "한국어" },
    ku: { flag: "iq", name: "Kurdî (Kurmancî)" },
    ky: { flag: "kg", name: "Кыргызча" },
    lo: { flag: "la", name: "ລາວ" },
    lv: { flag: "lv", name: "Latviešu valoda" },
    lt: { flag: "lt", name: "Lietuvių" },
    lb: { flag: "lu", name: "Lëtzebuergesch" },
    mk: { flag: "mk", name: "Македонски" },
    mg: { flag: "mg", name: "Malagasy" },
    ms: { flag: "my", name: "Melayu" },
    ml: { flag: "in", name: "മലയാളം" },
    mt: { flag: "mt", name: "Malti" },
    mi: { flag: "nz", name: "Maori" },
    mr: { flag: "in", name: "मराठी" },
    mn: { flag: "mn", name: "Монгол хэл дээр" },
    my: { flag: "mm", name: "မြန်မာ (ဗမာ)" },
    ne: { flag: "np", name: "नेपाली" },
    no: { flag: "no", name: "norsk" },
    ps: { flag: "af", name: "پښتو" },
    fa: { flag: "ir", name: "فارسی" },
    pl: { flag: "pl", name: "Polskie" },
    pt: { flag: "pt", name: "português" },
    pa: { flag: "in", name: "ਪੰਜਾਬੀ" },
    ro: { flag: "ro", name: "Română" },
    ru: { flag: "ru", name: "русский" },
    sm: { flag: "ws", name: "Samoa" },
    gd: { flag: "gb", name: "Gàidhlig na h-Alba" },
    sr: { flag: "rs", name: "Српски" },
    st: { flag: "ls", name: "Sesotho" },
    sn: { flag: "zw", name: "Shona" },
    sd: { flag: "pk", name: "سنڌي" },
    si: { flag: "lk", name: "සිංහල" },
    sk: { flag: "sk", name: "slovenský" },
    sl: { flag: "si", name: "Slovenščina" },
    so: { flag: "so", name: "Somali" },
    su: { flag: "sd", name: "Basa Sunda" },
    sw: { flag: "tz", name: "Kiswahili" },
    sv: { flag: "se", name: "svenska" },
    tg: { flag: "tj", name: "Тоҷикӣ" },
    ta: { flag: "in", name: "தமிழ்" },
    th: { flag: "th", name: "ไทย" },
    tr: { flag: "tr", name: "Türk" },
    uk: { flag: "ua", name: "Українська" },
    ur: { flag: "pk", name: "اردو" },
    uz: { flag: "uz", name: "O'zbek" },
    vi: { flag: "vn", name: "Tiếng Việt" },
    cy: { flag: "gb", name: "Cymraeg" },
    xh: { flag: "za", name: "isiXhosa" },
    yi: { flag: "il", name: "Yiddish" },
    yo: { flag: "ng", name: "Yorùbá" },
    zu: { flag: "za", name: "Zulu" }
};
