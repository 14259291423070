import axios from "axios";
import _window from "@/libraries/window";
import Vue from "@/libraries/vue";
import swal from "@/libraries/sweetalert";
import { i18n } from "@/plugins/i18n";
import { router } from "@/plugins/router";
import { timeout, stringify } from "@/assets/js/utils";
import { silentReport } from "@/assets/js/report";
import { BROKEN_STORAGE, persistent } from "@/assets/js/storage";
import { error } from "@/assets/js/exceptions";
import usersStore from "@/store/users";
const axiosInstance = axios.create();
export default axiosInstance;
axiosInstance.interceptors.request.use(async function (config) {
    if (!config.url) {
        console.warn("!config.url", config);
        return config;
    }
    if ("undefined" == typeof config.headers.Authorization) {
        const Authorization = await persistent.getItem("Authorization");
        if (Authorization) {
            config.headers.Authorization = Authorization;
        }
    }
    return config;
});
axiosInstance.interceptors.response.use(function (response) {
    const headers = response.headers;
    const Authorization = headers.authorization;
    if (Authorization) {
        persistent.setItem("Authorization", Authorization);
        axiosInstance.defaults.headers.Authorization = Authorization;
    }
    return response;
}, async function (axiosError) {
    console.log("axiosError", axiosError);
    const response = "object" == typeof axiosError ? axiosError.response : null;
    const config = "object" == typeof axiosError ? axiosError.config : null;
    const message = "object" == typeof axiosError ? axiosError.message : "" || "";
    const configUrl = (config && config.url) ? config.url : "";
    const failSafe = configUrl.includes("failsafe");
    if (failSafe)
        return response;
    const data = response ? response.data : null;
    const status = response ? Number(response.status) : null;
    const statusText = response ? response.statusText : null;
    let msg = data || message || statusText;
    if (msg[0] === "<" && msg.length > 100) {
        if (404 == status) {
            return;
        }
    }
    console.log("axiosError", msg, axiosError);
    if (!process.client)
        return;
    const lowerMsg = message ? message.toLowerCase() : "";
    const lowerData = data ? stringify(data).toLowerCase() : "";
    const networkError = lowerMsg.includes("network error") || lowerMsg.includes("timeout of 0ms exceeded") || lowerMsg.includes("endpoint request timed out");
    const unknownError = lowerData.includes("internal server error");
    const timeoutError = lowerData.includes("connection lost") || lowerData.includes("too many requests");
    const retryError = networkError || unknownError || timeoutError;
    const getRequest = config && "string" == typeof config.method && ("get" == config.method.toLowerCase() || "options" == config.method.toLowerCase());
    console.log("axios response error", msg, getRequest, axiosError, config);
    if (retryError && getRequest) {
        if (config._retry) {
            await timeout(5000);
        }
        config._retry = true;
        console.log("retryError requesting again.. (" + configUrl + ")", config);
        return axiosInstance.request(config);
    }
    if (!configUrl) {
        silentReport("axios error !config", {
            response,
            config
        });
    }
    const isRelativePath = configUrl && 0 !== configUrl.indexOf("http");
    const location = _window.location;
    const domainMatches = configUrl && configUrl.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    const domain = domainMatches && domainMatches[1];
    const sameDomain = domain && location && domain.includes(location.host);
    const comoquieroDomain = domain && domain.includes("comoquiero.net");
    const localhostDomain = domain && domain.includes("localhost");
    if (isRelativePath || sameDomain || comoquieroDomain || localhostDomain) {
        if (401 == status) {
            error401(configUrl);
            return;
        }
        if (403 == status) {
            error403();
            return;
        }
    }
    if (unknownError) {
        silentReport(msg, { config });
    }
    else {
        error(msg, { config });
    }
    return Promise.resolve(false);
});
function error401(request) {
    console.log("error401 request", request, request.endsWith("users/me"));
    if (!request.endsWith("users/me")) {
        usersStore.login();
    }
}
async function error403() {
    console.log("403");
    const ok = await swal({
        title: "" + i18n.t("axios_error_403_title") || "error 403",
        text: "" + i18n.t("axios_error_403_text")
            || "Parece que no tienes permisos para visualizar esta página. Quieres loguearte con otro usuario?",
        buttons: [true, true]
    });
    if (ok) {
        adminLoginDialog();
    }
    else {
        router.push("/");
    }
}
async function adminLoginDialog() {
    usersStore.logout();
    if (!BROKEN_STORAGE) {
        Vue.set(usersStore.loginDialog, "reload", true);
    }
    usersStore.login();
}
